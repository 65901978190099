html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

.app {
  width: 480px;
  margin: 0 auto;
  padding: 13px;
}

nav ul {
  list-style: none;
  display: flex;
  background-color: #61dafb;
  margin-bottom: 12px;
}

nav ul li {
  padding: 10px;
}

nav ul li a {
  color: #282c34;
  font-weight: bold;
  text-decoration: none;
  font-size: calc(1.0px + 2vmin);
}

.current {
  border-bottom: 4px solid white;
}

h1 {
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: calc(4px + 2vmin);
}

p {
  margin-bottom: 4px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(4px + 2vmin);
  font-weight: bolder;
  color: #282c34;
}

.App-subHeader {
  background-color: white;
  font-weight: bold;
  min-height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding-left: 3px;
  font-size: calc(4px + 2vmin);
  color: #282c34;
}

.App-text {
  background-color: white;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  padding: 1px;
  padding-left: 15px;
  padding-right: 20px;
  justify-content: left;
  font-size: calc(3px + 2vmin);
  color:  #282c34;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
